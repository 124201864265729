module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Well Child","short_name":"The Well Child","start_url":"/","background_color":"#ffffff","theme_color":"#39CDE0","display":"minimal-ui","icon":"content/assets/doctor-2411135_1280.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"91e13bdca519fae2b0b4331ef931deca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://e6292b9337954a0e8f07672e0d3accd7@sentry.io/1804852","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"analytics":true},"credentials":{"apiKey":"AIzaSyC7vIjGwuSOdr0yDoMIKrgerPlWaL-yFLI","authDomain":"dr-nadiv.firebaseapp.com","databaseURL":"https://dr-nadiv.firebaseio.com","projectId":"dr-nadiv","storageBucket":"dr-nadiv.appspot.com","messagingSenderId":"268607776968","appId":"1:268607776968:web:3639471a5a4fd8f22fddfd","measurementId":"G-P1KNZQTJ42"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
